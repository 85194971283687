import { LookupResponse } from '../../../models/lookup-response.interface'

const sampleLookupData = {
  fullname: 'domainname.com',
  status: 'unavailable',
  total_length: '10',
  age: '26 Years 5 months 6 days',
  first_discovery: '27 years',
  word_count: '2',
  vowels: '5',
  consonants: '5',
  syllable_count: '3',
  pronounceability: '5',
  simplicity: '5',
  complexity: '2',
  readability: '7',
  registerd_tlds: '249',
  related_domains: '5653',
  monthly_search_volume: '246000',
  cpc: '0.70',
  paid_competition: '0.45',
  domain_authority: '68',
  page_authority: '53',
  citation_flow: '36',
  trust_flow: '23',
  equity: '3831',
  popularity_score: '100',
  total_backlinks: '2716',
  domain_rank: '2632680',
  traffic: '157',
  domain_reputation: '-53',
  domain_threat_level: 'favorable',

  keyword_efficiency_index: '351429',
  number_of_indexed_pages: '3',
  keyword_in_urls: '1',
  exact_domain_match: '1',
}

interface LookupChild {
  name: string
  value?: string
  label?: string
}

export interface LookupData extends LookupChild {
  children?: LookupChild[]
}

export const constructLookupData = async (
  response?: LookupResponse
): Promise<LookupData[]> => {
  let data = response?.data?.[0] || response?.data

  if (!data) {
    data = sampleLookupData
  }

  return [
    {
      name: 'Domain',
      value: data.fullname,
      label:
        'Domain name is the name of the website. It is the address of the website on the internet. It is the unique name that identifies the website. It is the name that is used to access the website.',
    },
    {
      name: 'Availability',
      value: data.status,
      label:
        'Domain availability is the status of the domain name. It can be available or unable to register.',
    },
    {
      name: 'Length',
      value: data.total_length,
      label:
        'Length of the domain name is the number of characters in the domain name. The shorter the domain name, the better it is.',
    },
    {
      name: 'Age',
      value: data.age,
      label:
        'Age of the domain name is the time since the domain name was registered. The older, the better it is.',
    },
    {
      name: 'First Discovery',
      value: data.first_discovery,
      label:
        'First discovery of the domain name is the time since the domain name was first discovered on the wayback machine. The older,the better it is.',
    },
    {
      name: 'Word Count',
      value: data.word_count,
      label:
        'Word count of the domain name is the number of words in the domain name. Less words, the better.',
    },
    {
      name: 'Vowels',
      value: data.vowels,
      label:
        '',
    },
    {
      name: 'Consonants',
      value: data.consonants,
      label:
        '',
    },
    {
      name: 'Syllable Count',
      value: data.syllable_count,
      label:
        'Syllable count of the domain name is the number of syllables in the domain name.',
    },
    {
      name: 'Pronounceability',
      value: data.pronounceability,
      label:
        'Pronounceability of the domain name is the ease with which the domain name can be pronounced. The easier the domain name is to pronounce, the better it is.',
    },
    {
      name: 'Readability',
      value: data.readability,
      label:
        'Readability of the domain name is the ease with which the domain name can be read. The easier the domain name is to read, the better it is.',
    },
    {
      name: 'Complexity',
      value: data.complexity,
      label:
        'Domain complexity. The lower the complexity, the better it is.',
    },
    {
      name: 'Simplicity',
      value: data.simplicity,
      label:
        'Simplicity of the domain name is the ease with which the domain name can be understood.',
    },
    {
      name: 'Registered Tlds',
      value: data.registerd_tlds,
      label: 'Number of TLDs the domain name is registered in. The more, the better.',
    },
    {
      name: 'Related Domains',
      value: data.related_domains,
      label:
        'Number of times the domain name was mentioned in other registered domain names. The more, the better.',
    },
    {
      name: 'Search Volume',
      value: data.monthly_search_volume,
      label:
        'Monthly search volume of the domain name is the number of times the domain name was searched for in the last month.',
    },
    {
      name: 'CPC',
      value: data.cpc,
      label:
        'CPC of the domain name is the cost per click of the domain name. The higher the CPC, the better it is.',
    },
    {
      name: 'Competition',
      value: data.paid_competition,
      label:
        'Competition of the domain name is the number of advertisers competing for the domain name. The lower the competition, the better it is.',
    },
    {
      name: 'Backlinks',
      value: data.total_backlinks,
      label:
        'Backlinks of the domain name is the number of websites that link to the domain name. The more, the better it is.',
    },

    {
      name: 'Domain Authority',
      value: data.domain_authority,
      label:
        'Domain Authority of the domain name is the score of the domain name. The higher the score, the better it is.',
    },
    {
      name: 'Page Authority',
      value: data.page_authority,
      label:
        'Page Authority of the domain name is the score of the domain name. The higher the score, the better it is.',
    },
    {
      name: 'Citiation Flow',
      value: data.citation_flow,
      label:
        'Citation Flow is a score which reflects the quantity of links that point to any given website. The higher the score, the better it is.',
    },
    {
      name: 'Trust Flow',
      value: data.trust_flow,
      label:
        'Trust Flow is a metric that measures the quality and trustworthiness of the links pointing to a website. It is based on the idea that links from reputable and authoritative websites are more valuable. A higher Trust Flow score indicates a link profile consisting of high-quality, trustworthy backlinks.',
    },
    
    {
      name: 'Domain Rank',
      value: data.domain_rank,
      label:
        'Domain Rank is a score of the domain name. The higher the score, the better it is.',
    },
    {
      name: 'Domain Traffic',
      value: data.traffic,
      label:
        'Number of users expected to visit the website during the following month.',
    },
    {
      name: 'Equity Score',
      value: data.equity,
      label:
        ' Equity Score of the domain name is the score of the domain name. The higher the score, the better it is.',
    },
    {
      name: 'Popularity Score',
      value: data.popularity_score,
      label:
        ' Popularity Score of the domain name is the score of the domain name. The higher the score, the better it is.',
    },
    {
      name: 'Keyword efficiency index',
      value: data.keyword_efficiency_index,
      label:
        'Keyword efficiency index of the domain name is the score of the domain name. The higher the score, the better it is.',
    },
    {
      name: 'Indexed Pages',
      value: data.number_of_indexed_pages,
      label:
        'Number of indexed pages on Google for the domain name. The more, the better it is.',
    },
    {
      name: 'Exact Domain Match',
      value: data.exact_domain_match,
      label:
        'Exact domain match of the domain name is the number of times the domain name was sold for the exact keyword(s) and tld.',
    },
    {
      name: 'Keyword in URLs',
      value: data.keyword_in_urls,
      label:
        'Number of times keyword(s) were mentioned in the URLs of the domain name. The more, the better it is.',
    },
    {
      name: 'Domain Reputation',
      value: data.domain_reputation,
      label:
        'Domain Reputation of the domain name is the score of the domain name.',
    },
    {
      name: 'Threat Level',
      value: data.domain_threat_level,
      label:
        ' Threat Level of the domain name is the score of the domain name. The lower the score, the better it is.',
    },
    {
      name: 'Similar Sales',
      children: data?.similar_sales?.map((sale) => {
        return {
          name: sale.fullname,
          value: `$${sale.price}`,
          label: ' Sold domains which are similar to this domain name.',
        }
      }),
    },
  ]
}

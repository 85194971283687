import { SkeletonText } from '@chakra-ui/react'
import * as yup from 'yup'
import SearchIcon from './svgs/search.svg'
import { useQuery } from 'react-query'
import React from 'react'
import { constructLookupData, LookupData } from './lookup-data.utils'
import styles from './InteractiveLookup.module.scss'
import { Error } from 'components/common/Texts/Error/Error'
import Input from 'components/common/Input/Input'
import {
  ListItemWithTooltip,
  ListItemContent,
  ListItemWithoutTooltip,
} from './ListItems'
import { domainService } from '../../../services/domains.service'
import { getRootDomain } from 'utils/parseUrl'
import { domainEmptyError, domainInvalidError } from 'constants/errors'
import { domainRegex } from 'constants/regex'
import { Form, Formik } from 'formik'
import classNames from 'classnames'

export default function InteractiveLookup() {
  const [domain, setDomain] = React.useState<string>('')
  const [lookupData, setLookupData] = React.useState<LookupData[]>([])
  const [fullLookupData, setFullLookupData] = React.useState<LookupData[]>([])
  const [hasScrolled, setHasScrolled] = React.useState(false)
  const { isLoading, error, data } = useQuery<any, any, any, string[]>(
    ['domains/appraise', domain],
    ({ queryKey: [_, domain] }) => domainService.appraise(domain),
    { enabled: domain !== '', retry: false }
  )

  const DomainValidationSchema = yup
    .object()
    .noUnknown()
    .shape({
      domain: yup
        .string()
        .trim()
        .required(domainEmptyError)
        .test('cleanDomainCheck', domainInvalidError, (value) => {
          return domainRegex.test(getCleanedDomain(value)) ?? false
        }),
    })

  const getCleanedDomain = (domain: string) => {
    if (!domain) {
      return ''
    }
    return getRootDomain(domain.replaceAll(' ', ''))
  }

  const handleSubmit = ({ domain }) => {
    //Setting domain will cause the useQuery to refetch
    setDomain(getCleanedDomain(domain))
  }
  const refreshLookupData = React.useCallback(async () => {
    const fullLookupData = await constructLookupData(data?.data)
    setFullLookupData(fullLookupData)
    setLookupData(hasScrolled ? fullLookupData : fullLookupData.splice(0, 10))
  }, [data?.data, hasScrolled])

  React.useEffect(() => {
    refreshLookupData()
  }, [refreshLookupData])

  const errorObj = error?.response?.data

  return (
    <div className={styles.box}>
      <Formik
        validationSchema={DomainValidationSchema}
        onSubmit={handleSubmit}
        validateOnChange={false}
        validateOnBlur={true}
        initialValues={{ domain: '' }}
      >
        <Form>
          <div className={styles.inputSearchWrap}>
            <div className={styles.inputWrap}>
              <Input
                name="domain"
                placeholder="Check a domain e.g domain.com"
                className={styles.input}
                inputErrorClassName={styles.inputError}
              />
              <SearchIcon className={styles.icon} />
            </div>
            <button
              type="submit"
              className={
                isLoading ? styles.checkButtonDisabled : styles.checkButton
              }
              disabled={isLoading}
            >
              Check
            </button>
          </div>
        </Form>
      </Formik>

      <div
        className={styles.lookupListWrap}
        onScroll={(e: any) => {
          if (e.target.scrollTop > 0 && !hasScrolled) {
            setHasScrolled(true)
            setLookupData(fullLookupData)
          }
        }}
      >
        <div className={styles.lookupList}>
          <ul
            className={classNames(
              styles.listContent,
              isLoading && styles.loading
            )}
          >
            {isLoading ? (
              <SkeletonText noOfLines={14} spacing="4" height="10" />
            ) : error ? (
              <Error>Error: {errorObj?.message || 'Unknown'}</Error>
            ) : (
              lookupData.map((item) => {
                if (item?.children) {
                  return (
                    <li className={styles.subItemWrap} key={item.name}>
                      <ListItemWithoutTooltip>
                        <ListItemContent name={item.name} />
                      </ListItemWithoutTooltip>

                      <li className={styles.subItem}>
                        {item.children.map((child) => (
                          <ListItemWithTooltip
                            key={child.name}
                            label={child.label}
                            iconFill="#046dc1"
                          >
                            <ListItemContent
                              name={child.name}
                              value={child.value}
                            />
                          </ListItemWithTooltip>
                        ))}
                      </li>
                    </li>
                  )
                }

                return (
                  <ListItemWithTooltip key={item.name} label={item.label}>
                    <ListItemContent name={item.name} value={item.value} />
                  </ListItemWithTooltip>
                )
              })
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}

import axios from 'axios'
import {
  AllBackordersParams,
  AllBackordersResponse,
  AllTrendsParams,
  BackordersDetails,
  BackordersResponse,
  ContactOwnerRequestDetails,
  ContactOwnerRequestResponse,
  CreateUserBackorderResponse,
  DomainDetails,
  DomainsResponse,
} from 'models/domains.interface'
import { LookupResponse } from '../models/lookup-response.interface'
import { BishopiBaseService } from './bishopi-base.service'
import { getRootDomain } from '../utils/parseUrl'
import {
  AllKeywordResponse,
  AllTrendsResponse,
} from 'components/DomainTrends/TrendsFilters/models/TrendsSideFilter.interface'

class DomainsService extends BishopiBaseService {
  appraise(url: string) {
    const domain = getRootDomain(url)
    return axios.get<LookupResponse>(
      this.getPath(`/domains/appraise/${domain}`),
      { data: { domain } }
    )
  }

  getUserBackorders(params: BackordersDetails, token?: string) {
    return axios.get<BackordersResponse>(
      this.getPath(`/backorders/my-orders/?${params}`),
      {
        headers: {
          Authorization: token ? `Token ${token}` : '',
        },
      }
    )
  }

  cancelUserBackorder(id: number) {
    return axios.delete(this.getPath(`/backorders/cancel/${id}/`))
  }

  getUserDomains(params: DomainDetails, token?: string) {
    return axios.get<DomainsResponse>(
      this.getPath(`/domains/accounts/my-domains/?${params}`),
      {
        headers: {
          Authorization: token ? `Token ${token}` : '',
        },
      }
    )
  }

  getAllBackorders(params: AllBackordersParams) {
    return axios.get<AllBackordersResponse>(
      this.getPath(`/backorders/?${params}`)
    )
  }

  createUserBackorder(id: number, payment_type: 'paypal' | 'stripe') {
    return axios.post<CreateUserBackorderResponse>(
      this.getPath('/backorders/create/'),
      {
        domain: id,
        payment_type,
      }
    )
  }

  createBulkBackorders(domains: string) {
    return axios.post(this.getPath('/backorders/bulk/'), {
      domains,
    })
  }

  getAllTrends(params: AllTrendsParams, token) {
    return axios.get<AllTrendsResponse>(this.getPath(`/trends/?${params}`), {
      headers: {
        Authorization: token ? `Token ${token}` : '',
      },
    })
  }
  getKeywordTrend(keyword: string, filters: string) {
    return axios.get<AllKeywordResponse>(
      this.getPath(`/trends/${keyword}?${filters}`)
    )
  }

  contactOwnerRequest(details: ContactOwnerRequestDetails) {
    return axios.post<ContactOwnerRequestResponse>(
      this.getPath(`/domains/contact/`),
      details
    )
  }
}

export const domainService = new DomainsService()
